import React, { useState } from 'react';
import { Card, CardHeader, CardBody, Col } from 'reactstrap';
import classnames from 'classnames';
import './JsonEditor.scss';
import Icon from '../common/Icon';
import { TooltipIcon } from '../common/Tooltip';
import ThemeContext from '../util/ThemeContext';


interface JsonEditorProps {
    index: number;
    name: string;
    data: any;
    total: number;
    expanded: Array<string>;
    filter?: string;
    expandedAll: boolean;
    search: string;
    searchKeyMatches: Array<string>;
    onToggleExpand(key: string): void;
    onChange(index: number, key: string, value: string): void;
    onSave(index: number): void;
    onRemove(index: number): void;
}

export default ({ ...props }: JsonEditorProps) => {
    const { search, searchKeyMatches } = props;
    let cardWidth = '12';

    switch (props.total) {
        case 1:
        default:
            cardWidth = '12';
            break;
        case 2:
            cardWidth = '6';
            break;
        case 3:
            cardWidth = '4';
            break;
    }

   const merged = searchKeyMatches.concat(search ? [search] : []);

    const roots = Object.keys(props.data).filter(x => merged.length === 0 || merged.some(y => y.startsWith(x) || x.startsWith(y))).map(x => <JsonProperty
        key={x}
        dataKey={x}
        index={props.index}
        expanded={props.expanded}
        expandedAll={props.expandedAll}
        search={props.search}
        searchKeyMatches={props.searchKeyMatches}
        onChange={props.onChange}
        onToggleExpand={props.onToggleExpand}
        data={props.data[x]}
    />).filter(x => !!x);


    return (
        <ThemeContext.Consumer>
            {(context) => (
                <Col md={cardWidth} className="mt-3">
                    <Card className={classnames({ 'bg-dark': context.theme === 'dark' }, { 'text-white': context.theme === 'dark' })}>
                        <CardHeader className="d-flex justify-content-between align-items-center">
                            <span>

                                <strong>{props.index + 1}.</strong> {props.name}
                            </span>
                            <span>
                                <TooltipIcon title="Save" icon="save" callback={() => { props.onSave(props.index) }} className="no-select" />
                                <TooltipIcon title="Remove" icon="trash-alt" iconClassName="ml-2 text-danger" callback={() => { props.onRemove(props.index) }} className="no-select" />
                            </span>
                        </CardHeader>
                        <CardBody>
                            {roots.length > 0 ? <ul className="attr-tree root">
                                {roots}
                            </ul> : 'Nothing found matching the current search criteria...'}
                        </CardBody>
                    </Card>
                </Col>
            )
            }
        </ThemeContext.Consumer >

    )
}

interface JsonPropertyProps {
    dataKey: string;
    parentDataKey?: string;
    data: any;
    expanded: Array<string>;
    filter?: string;
    index: number;
    search: string;
    searchKeyMatches: Array<string>;
    expandedAll: boolean;
    onToggleExpand(key: string): void;
    onChange(index: number, key: string, value: string): void;
}

class JsonProperty extends React.Component<JsonPropertyProps> {
    // shouldComponentUpdate(nextProps: JsonPropertyProps) {
    //     const props = this.props;
    //     const stringChanged = typeof props.data === 'string' && props.data !== nextProps.data;
    //     const dataChanged = JSON.stringify(props.data) !== JSON.stringify(nextProps.data);
    //     const expandedAllChanged = props.expandedAll !== nextProps.expandedAll;
    //     const searchChanged = props.search !== nextProps.search;
    //     const expandedChanged = JSON.stringify(props.expanded) !== JSON.stringify(nextProps.expanded)
    //     return stringChanged || dataChanged || expandedAllChanged || expandedChanged || searchChanged;
    // }
    shouldComponentUpdate(nextProps: JsonPropertyProps) {
        const props = this.props;
        const stringChanged = typeof props.data === 'string' && props.data !== nextProps.data;
        // const expandedAllChanged = props.expandedAll !== nextProps.expandedAll;
        // const searchChanged = props.search !== nextProps.search;
        // const expandedChanged = props.expanded.includes(props.dataKey) !== nextProps.expanded.includes(props.dataKey);
        return stringChanged || typeof props.data === 'object';// expandedAllChanged || searchChanged || expandedChanged;
    }
    render() {
        const props = this.props;
        // console.log(props.dataKey);
        const isString = typeof props.data === 'string';
        // const isTemplate = isString && props.data.match(/(%{[A-z]*})/g);
        const containsBrackets = isString && props.data.match(/\[[\w|\s]*\]/g);
        const merged = props.searchKeyMatches.concat(props.search ? [props.search] : []);
        const isExpanded = !isString && ((merged.length > 0 || props.expandedAll) || !!props.expanded.find(x => x === props.dataKey));
        const hasValue = !!props.data;
        let title = props.dataKey;
        const isNested = title.lastIndexOf('.');
        if (isNested) {
            title = title.substring(isNested + 1);
        }
        let children: Array<any> = [];

        if (!isString) {
            children = Object.keys(props.data)
                .filter(x => merged.length === 0 || merged.some(y => `${props.dataKey}.${x}`.startsWith(y)))
                .map(x => <JsonProperty
                    key={`${props.dataKey}.${x}`}
                    dataKey={`${props.dataKey}.${x}`}
                    data={props.data[x]}
                    filter={props.filter}
                    expanded={props.expanded}
                    expandedAll={props.expandedAll}
                    search={props.search}
                    searchKeyMatches={props.searchKeyMatches}
                    onToggleExpand={props.onToggleExpand}
                    onChange={props.onChange}
                    index={props.index}
                />)
                .filter(x => !!x);
            if (children.length === 0) return null;
        }

        return (
            <ThemeContext.Consumer>
                {(context) => (
                    <li>
                        {isString && (
                            <div className="form-group row">
                                <label htmlFor={`${props.index}_${props.dataKey}`} className={classnames("col-form-label col-form-label-sm c-pointer", { 'text-warning': containsBrackets }, { 'text-danger': !hasValue })}>
                                    <Icon name='tag' />&nbsp;{title}
                                </label>
                                <div className="flex-grow">
                                    <TextProperty type="text" id={`${props.index}_${props.dataKey}`} className={classnames("ml-3 form-control form-control-sm", { 'bg-dark': context.theme === 'dark' }, { 'text-white': !containsBrackets && context.theme === 'dark' }, { 'text-warning': containsBrackets })} value={props.data} onChange={value => { props.onChange(props.index, props.dataKey, value); }} />
                                </div>
                            </div>
                        )}
                        {!isString && (
                            <>
                                <label onClick={() => { props.onToggleExpand(props.dataKey); }} className="col-form-label col-form-label-sm c-pointer"><Icon className="expand-icon" name={isExpanded ? 'angle-down' : 'angle-right'} />&nbsp;{title}</label>
                                {isExpanded && <ul className="attr-tree">
                                    {children}
                                </ul>}
                            </>
                        )}
                    </li>
                )}
            </ThemeContext.Consumer>

        )
    }
}

export const TextProperty = ({ ...props }: { id: string, placeholder?: string, onEnter?(value: string): void; type: string, onChange?(value: string): void, className: string, value: string, disabled?: boolean; }) => {
    const [value, setValue] = useState<string>(props.value);

    return (
        <input disabled={props.disabled} placeholder={props.placeholder} type={props.type} id={props.id} onKeyUp={e => { if (e.keyCode === 13 && props.onEnter) props.onEnter(value); }} onChange={e => { setValue(e.target.value); if (!e.target.value && props.onChange) props.onChange(''); }} className={props.className} value={value} onBlur={() => { if (props.value !== value && props.onChange) props.onChange(value); }} />
    )
}