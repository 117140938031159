export default {
    process(file: File): Promise<{ name: string, data: any }> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsText(file);
            reader.onload = (e) => {
                resolve({ name: file.name, data: JSON.parse(e.target!.result!.toString()) });
            };
            reader.onerror = error => reject(error);
        });
    },
    processBatch(files: Array<File>): Promise<Array<{ name: string, data: any }>> {
        const promises = files.map(x => this.process(x));
        return Promise.all(promises);
    },
    download(data: any, fileName: string) {
        const a = document.createElement('a');
        const file = new Blob([JSON.stringify(data, null, 4)], { type: 'application/json' });
        a.href = URL.createObjectURL(file);
        a.download = fileName;
        a.click();
    }
};