import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';

import { Container, Navbar, NavbarBrand, Nav, NavItem } from 'reactstrap';
import { Router, Route } from 'react-router';
import Translations from './views/Translations';
import BrowserHistory from './util/BrowserHistory';
import ThemeContext from './util/ThemeContext';
import { TooltipIcon } from './common/Tooltip';

const App: React.FC = () => {

  const [theme, setTheme] = useState('light');
  useEffect(() => {

    document.body.className = theme === 'dark' ? 'bg-secondary' : 'bg-white';
  });
  return (
    <>
      <ThemeContext.Provider value={{ theme, setTheme }}>
        <Navbar color={theme} dark={theme === 'dark'} light={theme === 'light'} expand="md" fixed="">
          <NavbarBrand href="/" onClick={e => { e.preventDefault(); e.stopPropagation(); BrowserHistory.push('/'); }}>
            <img src="/cogenius.png" height="24" className="mb-1" alt="logo" />
          </NavbarBrand>
          {/* <Nav className="mr-auto">
            <NavItem>
              <NavLink href="/translations" onClick={e => { e.preventDefault(); e.stopPropagation(); BrowserHistory.push('/translations'); }}>
                Translations
            </NavLink>
            </NavItem>
          </Nav> */}
          <Nav className="ml-auto">
            <NavItem>
              <TooltipIcon
                title={theme === 'dark' ? 'Toggle light mode' : 'Toggle dark mode'}
                placement="left"
                colorFor={theme === 'dark' ? 'white' : 'dark'}
                callback={() => { setTheme(theme === 'light' ? 'dark' : 'light') }}
                icon={theme === 'dark' ? 'lightbulb' : 'user-ninja'}
                className="nav-link"
              />
            </NavItem>
          </Nav>
        </Navbar>
        <Container fluid className="mt-3">
          <Router history={BrowserHistory}>
            {/* <Route path="/" component={Home} exact /> */}
            <Route path="/" component={Translations} />
          </Router>
        </Container>
      </ThemeContext.Provider>
    </>
  );
}

export default App;
