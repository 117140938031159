import React from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { library, IconName, IconPrefix, RotateProp, SizeProp, Transform } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

library.add(fas);

interface IconProps {
	name: IconName;
	prefix?: IconPrefix;
	className?: string;
	colorFor?: 'primary' |
	'secondary' |
	'success' |
	'warning' |
	'danger' |
	'info' |
	'light' |
	'dark' |
	'body' |
	'muted' |
	'white' |
	'black-50' |
	'white-50';
	rotation?: RotateProp;
	spin?: boolean;
	pulse?: boolean;
	size?: SizeProp;
	fixedWidth?: boolean;
	transform?: Transform | string;
	onClick?(): void;
}

export default class Icon extends React.Component<IconProps> {
	render() {
		const { name, prefix = 'fas', className, colorFor, rotation, spin, pulse, size, fixedWidth, transform, onClick } = this.props;

		return (
			<FontAwesomeIcon
				onClick={e => { if (onClick) { e.preventDefault(); e.stopPropagation(); onClick(); } }}
				fixedWidth={fixedWidth}
				rotation={rotation}
				spin={spin}
				pulse={pulse}
				icon={[prefix, name]}
				className={classnames(className, { [`text-${colorFor}`]: colorFor }, { 'c-pointer': !!onClick })}
				size={size}
				transform={transform}
			/>
		)
	}
}

interface IconStackProps {
	size?: SizeProp;
}

export class IconStack extends React.Component<IconStackProps> {
	render() {
		const { size = '1x', children } = this.props;
		return <span className={classnames('fa-layers fa-fw', { [`fa-${size}`]: size })}>{children}</span>;
	}
}