import React, { useState } from 'react';
import { Row, Col, Card, CardHeader, CardBody, ListGroup, ListGroupItem, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Dropzone from 'react-dropzone';
import classnames from 'classnames';
import Icon from '../common/Icon';
import flatten from 'flat';
import './Translations.scss';
import FileHelper from '../util/FileHelper';
import JsonEditor, { TextProperty } from '../components/JsonEditor';
import set from 'lodash.set';
import { TooltipIcon } from '../common/Tooltip';
import ThemeContext from '../util/ThemeContext';

const Translations = () => {

	const [files, setFiles] = useState<Array<{ name: string, data: any }>>([]);
	const [over, setOver] = useState<boolean>(false);
	const [expanded, setExpanded] = useState<Array<string>>([]);
	const [expandedAll, setExpandedAll] = useState<boolean>(false);
	const [search, setSearch] = useState<string>('');
	const [valueSearch, setValueSearch] = useState<string>('');
	const [searchLanguage, setSearchLanguage] = useState<string>('');
	const [languageDropdown, setLanguageDropdown] = useState<boolean>(false);

	const onToggleExpand = (key: string) => {
		const found = expanded.findIndex(x => x === key);
		const newExpanded = [...expanded];
		if (found > -1) {
			newExpanded.splice(found, 1);
		} else {
			newExpanded.push(key);
		}
		setExpanded(newExpanded);
	}

	const onChange = (index: number, key: string, value: string) => {
		const newFiles = [...files];
		newFiles[index].data = set(newFiles[index].data, key, value);
		setFiles(newFiles);
	}

	const onSave = (index: number) => {
		FileHelper.download(files[index].data, files[index].name);
	}

	const onRemove = (index: number) => {
		if (window.confirm('Are you sure you want to remove this file?')) {
			const newFiles = [...files];
			newFiles.splice(index, 1);
			setFiles(newFiles);
		}
	}
	
	let valueSearchKeyMatches:Array<string> = [];

	if(valueSearch && searchLanguage){
		const file = files.find(x => x.name === searchLanguage);
		if(file){
			const flattenedData:any = flatten(file.data);
			const allKeys = Object.keys(flattenedData).filter(x => flattenedData[x].toLowerCase().includes(valueSearch.toLowerCase()));
			valueSearchKeyMatches = allKeys;
			if(valueSearchKeyMatches.length === 0) {
				valueSearchKeyMatches.push('123456_KeyNotFound');
			}
		}
	}

	return (
		<ThemeContext.Consumer>
			{(context) => (
				<>
					<Row>
						<Col md="6">
							<Card color={context.theme as any} className={classnames({ "text-white": context.theme === 'dark' })}>
								<CardHeader>
									<Icon name="file-import" />&nbsp;Import
								</CardHeader>
								<ListGroup flush>
									{files.map((x, idx) => {
										return (
											<ListGroupItem key={idx} className={classnames("d-flex justify-content-between align-items-center", { 'bg-dark': context.theme === 'dark' })}>
												<span className="no-select"><strong>{idx + 1}.</strong> {x.name}</span>
												<span>
													<TooltipIcon title="Save" icon="save" callback={() => { onSave(idx); }} className="no-select" />
													<TooltipIcon title="Remove" icon="trash-alt" iconClassName="ml-2 text-danger" callback={() => { onRemove(idx); }} className="no-select" />
												</span>
											</ListGroupItem>
										)
									})}
								</ListGroup>
								<CardBody>
									<Dropzone
										accept={'application/json'}
										multiple={true}
										onDrop={e => {
											FileHelper.processBatch(e).then(x => { setExpanded([]); setFiles(files.concat(x)); setSearchLanguage(files.concat(x)[0].name); });
											setOver(false);
										}}
										onDragEnter={() => {
											setOver(true);
										}}
										onDragLeave={() => {
											setOver(false);
										}}
									>
										{({ getRootProps, getInputProps }) => (
											<section className={classnames('file-upload-drop-zone-container', { 'dark': context.theme === 'dark' })}>
												<div className={classnames('file-upload-drop-zone', { over })} {...getRootProps()}>
													<input {...getInputProps()} />
													<span className='file-upload-text-container'>
														<p className='file-upload-text'>Drag and drop the source files here<br />or<br />click here to browse</p>
													</span>
												</div>
											</section>
										)}
									</Dropzone>
								</CardBody>
							</Card>
						</Col>
						{files.length > 0 && <Col md="6">
							<Card color={context.theme} className={classnames({ "text-white": context.theme === 'dark' })}>
								<CardHeader>
									<Icon name="cog" /> Settings
								</CardHeader>
								<CardBody>
									<p>
										<TextProperty placeholder="Search for keys..." type="search" id="search" className={classnames('form-control search-control', { 'bg-dark': context.theme === 'dark' }, { 'text-white': context.theme === 'dark' })} onEnter={setSearch} onChange={setSearch} value={search} />
									</p>
									<div className="file-upload-value-search-container">
										<Dropdown disabled={files.length === 0} isOpen={languageDropdown} toggle={() => setLanguageDropdown(!languageDropdown)} color={context.theme}>
											<DropdownToggle caret color={context.theme}>
												{searchLanguage || 'Select language...'}
        									</DropdownToggle>
											<DropdownMenu color={context.theme}>
												{files.map(x => <DropdownItem key={x.name} color={context.theme} onClick={e => { e.preventDefault(); e.stopPropagation(); setSearchLanguage(x.name); }}>{x.name}</DropdownItem>)}
											</DropdownMenu>
										</Dropdown>
										<TextProperty disabled={!searchLanguage} placeholder="Search for values..." type="search" id="search" className={classnames('form-control search-control', { 'bg-dark': context.theme === 'dark' }, { 'text-white': context.theme === 'dark' })} onEnter={setValueSearch} onChange={setValueSearch} value={valueSearch} />
									</div>
									<p>
										<a href="/" className={classnames("c-pointer no-underline no-select", { 'text-white': context.theme === 'dark' }, { 'text-dark': context.theme === 'light' })} onClick={e => { e.preventDefault(); e.stopPropagation(); setExpandedAll(!expandedAll); }}><Icon name={expandedAll ? 'check-square' : 'square'} />&nbsp;Expand all nodes</a>
									</p>
								</CardBody>
							</Card>
						</Col>}
					</Row>
					<Row>
						{files.map((x, idx) => <JsonEditor
							key={idx}
							index={idx}
							name={x.name}
							data={x.data}
							total={files.length}
							onChange={onChange}
							onToggleExpand={onToggleExpand}
							expanded={expanded}
							expandedAll={expandedAll}
							onSave={onSave}
							searchKeyMatches={valueSearchKeyMatches}
							onRemove={onRemove}
							search={search}
						/>)}
					</Row>
				</>
			)}
		</ThemeContext.Consumer>

	)
};

export default Translations;