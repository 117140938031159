import React from "react";
import Icon from "./Icon";
import ReactTooltip from "react-tooltip";
import { IconPrefix, IconName, SizeProp } from "@fortawesome/fontawesome-svg-core";

interface TooltipProps {
    title: string;
    container?: string;
    delay?: any;
    html?: boolean;
    placement?: 'top' | 'right' | 'bottom' | 'left' | undefined;
    target?: string;
    iconClassName?: string;
    className?: string;
}

interface TooltipIconProps extends TooltipProps {
    icon: IconName;
    iconPrefix?: IconPrefix;
    iconSize?: SizeProp;
    colorFor?: 'primary' |
    'secondary' |
    'success' |
    'warning' |
    'danger' |
    'info' |
    'light' |
    'dark' |
    'body' |
    'muted' |
    'white' |
    'black-50' |
    'white-50';
    callback?(event: any): void;
}

interface TooltipButtonProps extends TooltipIconProps {
    disabled?: boolean;
}

interface TooltipIconButtonProps extends TooltipButtonProps { }

interface TooltipIconState {
    target: string;
}

export class TooltipIcon extends React.Component<
    TooltipIconProps,
    TooltipIconState
    > {
    constructor(props: TooltipIconProps) {
        super(props);
        this.state = {
            target:
                props.target ||
                `${Math.random()
                    .toString(36)
                    .substr(2, 9)}`
        };
    }
    render() {
        const { placement = 'top', iconClassName, className, callback } = this.props;
        const { target } = this.state;

        return (
            <>
                <span
                    data-tip=""
                    data-for={target}
                    style={{ cursor: 'pointer' }}
                    className={className}
                    onClick={e => { if (callback) { e.preventDefault(); e.stopPropagation(); callback(e); } }}
                >
                    <Icon
                        prefix={this.props.iconPrefix}
                        name={this.props.icon}
                        colorFor={this.props.colorFor}
                        size={this.props.iconSize}
                        className={iconClassName}
                    />
                </span>
                <ReactTooltip
                    html
                    multiline
                    id={target}
                    place={placement}
                    className={className}
                    getContent={() => this.props.title}
                />
            </>
        );
    }
}

interface TooltipProps {
    callback?(event: any): void;
    title: string;
    placement?: 'top' | 'right' | 'bottom' | 'left' | undefined;
    className?: string;
}

export class Tooltip extends React.Component<
    TooltipProps,
    TooltipIconState
    > {
    constructor(props: TooltipIconProps) {
        super(props);
        this.state = {
            target:
                props.target ||
                `${Math.random()
                    .toString(36)
                    .substr(2, 9)}`
        };
    }
    render() {
        const { callback, placement, className = 'fv-off' } = this.props;
        const { target } = this.state;

        return (
            <>
                <span
                    data-tip=""
                    data-for={target}
                    className={className}
                    style={{ cursor: 'pointer' }}
                    onClick={e => { if (callback) { e.preventDefault(); e.stopPropagation(); callback(e); } }}
                >
                    {this.props.children}
                </span>
                <ReactTooltip
                    html
                    multiline
                    id={target}
                    place={placement}
                    className={className}
                    getContent={() => this.props.title}
                />
            </>
        );
    }
}

export class TooltipIconButton extends React.Component<
    TooltipIconButtonProps,
    any
    > {
    constructor(props: TooltipIconButtonProps) {
        super(props);
        this.callback = this.callback.bind(this);
        this.state = {
            target:
                props.target ||
                `${Math.random()
                    .toString(36)
                    .substr(2, 9)}`
        };
    }
    callback = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        if (this.props.callback) {
            this.props.callback(event);
        }
    };
    render() {
        const { target } = this.state;
        return (
            <>
                <button
                    ref="btn"
                    disabled={this.props.disabled}
                    data-tip=""
                    data-for={target}
                    type="button"
                    className={this.props.className}
                    onClick={this.callback}
                >
                    <Icon
                        prefix={this.props.iconPrefix}
                        name={this.props.icon}
                        colorFor={this.props.colorFor}
                        className={this.props.iconClassName}
                    />
                    {this.props.children}
                </button>
                <ReactTooltip
                    html
                    multiline
                    id={target}
                    place={this.props.placement}
                    getContent={() => this.props.title}
                />
            </>
        );
    }
}


export class TooltipIconAnchorButton extends TooltipIconButton {
    render() {
        return (
            <a
                href="/"
                ref="btn"
                role="button"
                className={this.props.className}
                onClick={this.callback}>
                <Icon name={this.props.icon} className={this.props.iconClassName} prefix={this.props.iconPrefix} />
                {this.props.children}
            </a>);
    }
}